<template>
    <el-dialog
        title="重置密码"
        :visible.sync="visible"
        @open="resetDialog"
        width="20%"
        @close="handleClose"
    >
        <el-form :model="form" label-position="right" label-width="100px" :rules="rules">
            <el-form-item label="新密码" prop="newPWD">
                <el-input
                    type="password"
                    v-model.trim="form.newPWD"
                    placeholder="输入新密码"
                ></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="confirmPWD">
                <el-input
                    type="password"
                    v-model.trim="form.confirmPWD"
                    placeholder="输入确认密码"
                ></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="small" @click="submit"
                    >提交</el-button
                >
                <el-button size="small" @click="handleClose">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
export default {
    name: "resetpwd",
    props: {
        show: Boolean,
        userId: String
    },
    data() {
        return {
            visible: false,
            form: {
                confirmPWD: "",
                newPWD: ""
            },
            rules: {
                newPWD: [
                    {required: true, message: "新密码不为空!",trigger: "blur"},
                    { min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur' }
                ]
            }
        };
    },
    watch: {
        show: {
            handler() {
                this.visible = this.show;
            },
            immediate: true
        }
    },
    methods: {
        resetDialog() {
            this.form = {
                confirmPWD: "",
                newPWD: ""
            };
        },
        async submit() {
            if (this.form.confirmPWD !== this.form.newPWD) {
                this.$message.warning({
                    message: "密码不一致，请重新输入",
                    showClose: true
                });
                return false;
            }
            const result = await this.$http({
                url: "/sys/user/resetPassword",
                data: {
                   userId: this.userId,
                   password: this.form.newPWD,
                }
            });
            this.$message.success({
                message: result.msg,
                duration: 1000
            });
            this.handleClose();
        },
        handleClose() {
            this.$emit("close");
        }
    }
};
</script>

<style scoped></style>
