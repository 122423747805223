<template>
    <div>
        <el-form ref="checkForm" :model="formInline" label-width="100px">
            <el-row class="search">
                <el-col :span="18">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="用户名:" prop="userName">
                                <el-input v-model="formInline.userName"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="所属角色:" prop="roleId">
                                <el-select v-model="formInline.roleId">
                                    <el-option v-for="role in roleList" :key="role.id" :label="role.name" :value="role.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="所属机构:" prop="orgId">
                                <selectOrg placeholder="选择所属机构" ref="searchOrg" @select="searchOrg"></selectOrg>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="账号状态">
                                <el-select v-model="formInline.status">
                                    <el-option v-for="status in statusOptions" :key="status.id" :label="status.name" :value="status.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="6">
                    <el-row>
                        <el-col :span="24">
                            <el-form-item>
                                <el-button type="primary" @click="search">查询</el-button>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="reset">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-button type="primary" @click="add">新增</el-button>
                            <el-button type="primary" @click="batchDelete">批量删除</el-button>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-form>
        <div class="jy_table">
            <ctable
                ref="ctable"
                selectMode="check"
                :columns="columns"
                :data="tableData"
                :total="total"
                :btnList="btnList"
                operateWidth="300"
                @cellClick="cellBtnClick"
                @update="init"
            ></ctable>
        </div>

        <cdialog
            :show="showDialog"
            :columns="getRealColumns"
            title="用户"
            width="40%"
            ref="cdialog"
            :data="selData"
            :isEdit="isEdit"
            @close="closeDialog"
            @verify="saveOrUpdate"
            @upSuccess="upSuccess"
        >
            <template slot="orgId">
                <selectOrg placeholder="选择所属机构" :currentOrgName="selData.orgName" ref="selectOrg" @select="selectOrg"></selectOrg>
            </template>
        </cdialog>

        <!-- 重置密码 -->
        <resetpwd :userId="selData.userId" :show="showReset" @close="closeDialog"></resetpwd>
    </div>
</template>

<script>
    import selectOrg from "@/components/dialog/selectOrg";
    import resetpwd from "./resetpwd";
    import { getToken } from "@/utils/token";
    import _ from "loadsh";
    const statusOptions = [
        {
            name: "启用",
            id: "1"
        },
        {
            name: "禁用",
            id: "0"
        }
    ];

    export default {
        components: {
            selectOrg,
            resetpwd
        },
        computed: {
            getRealColumns() {
                if (this.isEdit) {
                    return this.columns;
                } else {
                    let arr = _.cloneDeep(this.columns);
                    const passwordCol = [
                        {
                            prop: "password",
                            title: "登录密码",
                            tag: "el-input",
                            unShow: true
                        },
                        {
                            prop: "rePassword",
                            title: "确认密码",
                            tag: "el-input",
                            unShow: true
                        }
                    ];
                    passwordCol.forEach((v, i) => {
                        arr.splice(2 + i, 0, v);
                    });
                    return arr;
                }
            }
        },
        methods: {
            batchDelete() {
                const selData = this.$refs.ctable.selection;
                if (selData.length <= 0) {
                    this.$message.warning({
                        message: "请选择要删除的人员",
                        duration: 1000
                    });
                    return;
                }
                this.$confirm("是否删除这些用户", "删除", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    const result = await this.$http({
                        url: "/sys/user/delete",
                        data: {
                            userId: selData.map(v => v.userId).join(",")
                        }
                    });
                    this.$message.success({
                        message: result.msg,
                        duration: 1000
                    });
                    this.init();
                });
            },
            cellBtnClick(index, btnIndex, item) {
                if (btnIndex === "edit") {
                    this.selData = item;
                    if (this.selData.roleIds) {
                        this.selData.roleIds = this.selData.roleIds.split(",");
                    }
                    this.selData.status = this.selData.status.toString();
                    this.showDialog = true;
                    this.isEdit = true;
                } else if (btnIndex === "delete") {
                    this.$confirm("是否删除此用户", "删除", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(() => {
                        this.$http({
                            url: "/sys/user/delete",
                            data: {
                                userId: item.userId
                            }
                        }).then(res => {
                            if (res.code === "0") {
                                this.$message.success({
                                    message: "删除成功",
                                    type: "success",
                                    duration: 1000
                                });
                                this.init();
                            }
                        });
                    });
                } else if (btnIndex === "reset") {
                    this.selData = item;
                    this.showReset = true;
                }
            },
            init() {
                const page = this.$refs.ctable.getPager();
                this.$http({
                    url: "/system/user/queryPage",
                    data: {
                        ...page,
                        ...this.formInline
                    }
                }).then(res => {
                    this.tableData = res.detail.list;
                    this.total = res.detail.total;
                });
            },
            search() {
                this.$refs.ctable.reset();
                this.init();
            },
            add() {
                this.showDialog = true;
            },
            reset() {
                this.formInline = {
                    userName: "",
                    roleId: "",
                    orgId: "",
                    status: ""
                };
                if (this.$refs.searchOrg) {
                    this.$refs.searchOrg.reset();
                }
                this.init();
            },
            closeDialog() {
                if (this.$refs.selectOrg) {
                    this.$refs.selectOrg.reset();
                }
                this.showDialog = false;
                this.selData = {};
                this.isEdit = false;
                this.showReset = false;
            },
            async saveOrUpdate(form) {
                let result = null;
                if (form.roleIds && form.roleIds.length) {
                    form.roleIds = form.roleIds.join(",");
                }
                if (this.isEdit) {
                    result = await this.$http({
                        url: "/sys/user/update",
                        data: form
                    });
                } else {
                    if (this.password === this.surePassword) {
                        result = await this.$http({
                            url: "/sys/user/save",
                            data: form
                        });
                    } else {
                        this.$message.warning({
                            message: "两次密码不一致,请重新输入"
                        });
                    }
                }
                this.$message.success({
                    message: result.msg,
                    duration: 1000
                });
                this.showDialog = false;
                this.init();
            },
            selectOrg(data) {
                const form = this.$refs.cdialog.getFormData();
                this.$set(form, "orgId", data.orgId);
            },
            searchOrg(data) {
                this.formInline.orgId = data.orgId;
            },
            upSuccess(res) {
                const form = this.$refs.cdialog.getFormData();
                this.$set(form, "logoImg", res.msg);
            },
            initBtns() {
                this.btnList.push({
                    title: "编辑",
                    index: "edit"
                });
                this.btnList.push({
                    title: "删除",
                    index: "delete"
                });
                this.btnList.push({
                    title: "重置密码",
                    index: "reset"
                });
            }
        },
        mounted() {
            this.$http({
                url: "/sys/role/getCombo"
            }).then(res => {
                this.roleList = res.detail;
                this.$set(this.columns[3], "options", res.detail);
            });
            this.$nextTick(() => {
                this.init();
            });
            this.initBtns();
        },
        data() {
            return {
                formInline: {
                    userName: "",
                    realName: "",
                    roleId: "",
                    orgId: ""
                },

                roleList: [],

                showDialog: false,
                isEdit: false,
                selData: {},

                showReset: false,

                tableData: [],
                total: 0,
                btnList: [],

                statusOptions,

                columns: [
                    {
                        prop: "userName",
                        title: "用户名",
                        tag: "el-input"
                    },
                    {
                        prop: "realName",
                        title: "姓名",
                        tag: "el-input"
                    },
                    {
                        prop: "orgId",
                        title: "所属机构",
                        tag: "slot",
                        formatter: (index, key, item) => {
                            return item["orgName"];
                        }
                    },
                    {
                        prop: "roleIds",
                        title: "所属角色",
                        tag: "el-select",
                        options: [],
                        formatter: (index, key, item) => {
                            return item.roleNames;
                        },
                        dfProps: {
                            name: "name",
                            value: "id"
                        },
                        props: {
                            multiple: true
                        }
                    },
                    {
                        prop: "enableTime",
                        title: "启用时间",
                        tag: "el-date-picker",
                        props: {
                            type: "datetime",
                            valueFormat: "yyyy-MM-dd HH:mm:ss"
                        },
                        minWidth: 220
                    },
                    {
                        prop: "disableTime",
                        title: "禁用时间",
                        tag: "el-date-picker",
                        props: {
                            type: "datetime",
                            valueFormat: "yyyy-MM-dd HH:mm:ss"
                        },
                        minWidth: 220
                    },
                    {
                        prop: "logoImg",
                        title: "企业logo",
                        tag: "el-upload",
                        props: {
                            action: "/emergency-admin/system/user/uploadLogo",
                            headers: {
                                token: getToken()
                            }
                        },
                        wh: {
                            width: 500,
                            height: 60
                        },
                        span: 24,
                        image: true,
                        unNeed: true,
                        tip: "图片高度不大于60px，宽度不大于500，且大小不超过5MB!"
                    },
                    {
                        prop: "status",
                        title: "账号状态",
                        tag: "el-radio-group",
                        formatter: (index, key, item) => {
                            if (item[key] == 1) {
                                return "启用";
                            } else {
                                return "禁用";
                            }
                        },
                        dfProps: {
                            name: "name",
                            value: "id"
                        },
                        options: statusOptions
                    },
                    {
                        prop: "createUserName",
                        title: "创建者"
                    },

                    {
                        prop: "createTime",
                        title: "创建日期",
                        minWidth: 220
                        // formatter: (index,key,item) => {
                        //   return formatDate(item[key],'yyyy-MM-dd hh:mm:ss')
                        // }
                    }
                ]
            };
        }
    };
</script>

<style scoped></style>
